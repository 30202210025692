/* eslint-disable no-restricted-globals */

import getResourceCacheKey from './utils/get-resource-cache-key';

const sw = self as unknown as ServiceWorkerGlobalScope & typeof globalThis;
sw.addEventListener('install', () => {});
sw.addEventListener('activate', () => {});

const cacheName = 'resource-cache';

function isOk(status: number): boolean {
  if (status >= 200 && status < 300) {
    return true;
  }
  return false;
}

let promiseCache: Promise<Cache> | undefined;

const ignoreContentTypes = [null, '', 'application/json', 'text/html', 'text/json', 'text/plain'];

sw.addEventListener('fetch', (evt) => {
  const { request } = evt;
  if (request.method !== 'GET') {
    return;
  }
  /**
   * no-cors请求，响应属于非透明请求（response.type === 'opaque'），如果存在错误，是没办法知道的（response.status === 0）
   * 为了避免错误一直存在缓存里边，导致一直无法正确，所以这种资源不缓存
   * @see {@link https://stackoverflow.com/questions/39109789/what-limitations-apply-to-opaque-responses}
   * @see {@link https://developers.google.com/web/tools/workbox/guides/handle-third-party-requests}
   */
  if (request.mode === 'no-cors') {
    return;
  }

  const cacheKey = getResourceCacheKey(request.url);
  if (!cacheKey) {
    return;
  }
  evt.respondWith(
    (async () => {
      promiseCache ??= caches.open(cacheName);
      const cache = await promiseCache;
      let response = await cache.match(cacheKey);
      if (!response) {
        response = await fetch(request);
        const contentType = response.headers.get('Content-Type');
        if (ignoreContentTypes.includes(contentType)) {
          return response;
        }
        if (isOk(response.status)) {
          cache.put(cacheKey, response.clone());
        }
      }
      return response;
    })()
  );
});
